.usertitle {
  font-weight: normal;
}

.button:global.ant-btn {
  color: inherit;
  width: 100%;
  text-align: left;
  padding: 0 15px;
  height: auto;
}

.button:hover:global.ant-btn {
  color: var(--wrw-highlight-positive-color);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}
