.status {
  padding: 0 4px 0 4px;
  border-radius: 0;
  display: inline-flex;
  font-size: 11px;
}

.active {
}

.standby {
  padding: 0 4px 0 4px;
  border: 1px solid var(--wrw-warning-color);
}

.finished {
  padding: 0 4px 0 4px;
  border: 1px solid var(--wrw-success-color);
}

.deleted {
  padding: 0 4px 0 4px;
  border: 1px solid var(--wrw-error-color);
}

.canceled {
  padding: 0 4px 0 4px;
  border: 1px solid var(--wrw-error-color);
}
