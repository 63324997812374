.externalLink:global.ant-menu-item {
  padding-left: 24px;
}

.iframe {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border: none;
  background: url('../assets/img/loading_indicator_bar_2.gif') center center no-repeat;
  padding: 0 var(--wrw-margin-md) var(--wrw-margin-md) var(--wrw-margin-md);
}
