.cookie_container {
  align-items: baseline;
  background-color: rgb(53, 53, 53);
  color: white;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100% !important;
  z-index: 999 !important;
  font-size: 18px;
  bottom: 0px;
  visibility: visible !important;
}

.cookie_content {
  flex: 1 0 300px !important;
  margin: 15px;
}

.cookie_button {
  background: rgb(255, 212, 45);
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}
