.pdfmodal {
}

.pdfmodal :global .ant-modal-header {
    background: black;
}

.pdfmodal :global .ant-modal-title {
    color: white;
}

.pdfmodal :global .ant-modal-close-x {
    color: white;
}

.pdfmodal :global .ant-modal-body {
    padding: 0;
    background: black;
}

.iframe {
    width: 100%;
    height: calc(90vh - 55px);
    border: none;
    padding: 0;
    margin: 0;
}
