.topRow {
  margin: var(--wrw-margin-sm) var(--wrw-margin-md) 0 var(--wrw-margin-md);
}

.bottomRow {
  margin: 0 var(--wrw-margin-md) var(--wrw-margin-sm) var(--wrw-margin-md);
  border-bottom: 1px solid var(--wrw-header-darkborder-color);
}

.kategorie {
  font-size: small;
}

.projectTitel {
  font-weight: bold;
  font-size: large;
}

.sapHeader {
  font-size: small;
  text-align: right;
}

.sapNumber {
  text-align: right;
  font-weight: bold;
  font-size: large;
}

.kennzeichen {
  margin-left: 10px;
}

.status {
  margin-left: 10px;
}
